import {IAppliedFilter} from "@/app/models/auctor";

export const evaluateExpressionWithFilters = (expression: string, appliedFilters: IAppliedFilter[]) => {
    try {
        expression = prepareExpression(expression)

        expression = populateExpressionWithAppliedFilters(expression, appliedFilters)

        const expressionFunction = new Function('appliedFilters', expression)

        return expressionFunction(appliedFilters)
    } catch (error) {
        console.info(`Error evaluating expression`, expression, error)
        return false
    }
}

export const evaluateExpressionWithFiltersAndRowData = (expression: string, data: any, appliedFilters: IAppliedFilter[], context?:any) => {
    try {
        expression = prepareExpression(expression, context)

        expression = populateExpressionWithAppliedFilters(expression, appliedFilters)

        const expressionFunction = new Function('data', 'appliedFilters', expression)

        return expressionFunction(data, appliedFilters)
    } catch (error) {
        console.info(`Error evaluating the following filter expression ${expression}`, error)
        return false
    }
}

export const prepareExpression = (expression: string, context?: string) => {
    if (!expression.includes('return')) {
        expression = `return ${expression}`
    }

    if (!context) {
        return expression
    }

    return `${context}
        ${expression}`
}

export const populateExpressionWithAppliedFilters = (expression: string, appliedFilters: IAppliedFilter[]) => {
    let initVariables = ``
    appliedFilters.forEach((filter: any) => {
        initVariables += `
            let ${filter.variableName} = null
        `
    })

    appliedFilters.forEach((filter: any) => {
        initVariables += `
            if (!${filter.variableName}) {
                ${filter.variableName} = appliedFilters.find(aFilter => aFilter.filterId === '${filter.filterId}')
            }`
    })

    return `${initVariables}\n ${expression}`
}
