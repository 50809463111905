import Filter, {FilterSkeleton} from "@/app/components/auctor/filter/Filter";
import {getFilterDisplayCondition} from "@/app/utils/filters-utils";
import {IAppliedFilter} from "@/app/models/auctor";
import AppForm from "@/app/components/atomic/AppForm";

interface FilterListProps {
    filtersConfig: any,
    appliedFilters: IAppliedFilter[],
    className?: string,
    updateAppliedFilter: any,
    displayNumberOnMobile?: number,
    isFilterListExpandedOnMobile?: boolean
}

export default function FilterList({filtersConfig, appliedFilters, updateAppliedFilter, className = '', displayNumberOnMobile = 2, isFilterListExpandedOnMobile = false}: FilterListProps) {
    const filtersToDisplayOnBothDevices = filtersConfig?.slice(0, displayNumberOnMobile);
    const filtersToHideOnMobile = filtersConfig?.slice(displayNumberOnMobile);

    return (
        <AppForm className={className}>
            {filtersToDisplayOnBothDevices?.map((filter: any, idx: number) => {
                if (getFilterDisplayCondition(filter, appliedFilters)) {
                    const appliedFilter = appliedFilters.find(f => f.filterId === filter.unique_id);
                    return (
                        <Filter
                            key={'filter-' + idx}
                            filterConfig={filter}
                            // @ts-ignore because if we have a filter we have an applied filter
                            appliedFilter={appliedFilter}
                            updateAppliedFilter={updateAppliedFilter}
                        />
                    )
                }
            })}

            {isFilterListExpandedOnMobile && filtersToHideOnMobile?.map((filter: any, idx: number) => {
                if (getFilterDisplayCondition(filter, appliedFilters)) {
                    const appliedFilter = appliedFilters.find(f => f.filterId === filter.unique_id);
                    return (
                        <Filter
                            key={'filter-' + idx}
                            filterConfig={filter}
                            // @ts-ignore because if we have a filter we have an applied filter
                            appliedFilter={appliedFilter}
                            updateAppliedFilter={updateAppliedFilter}
                        />
                    )
                }
            })}
        </AppForm>
    )
}

export function FiltersSkeleton({countDesktop = 3, countAllDisplays = 2, countMobile = 2}: {
    countDesktop?: number,
    countAllDisplays?: number,
    countMobile?: number,
}) {
    return (
        <>
            {Array.from(Array(countAllDisplays).keys())?.map((idx: number) => (
                <FilterSkeleton
                    key={idx}
                />
            ))}

            {Array.from(Array(countDesktop).keys())?.map((idx: number) => (
                <FilterSkeleton
                    className='hidden lg:block'
                    key={idx}
                />
            ))}
        </>
    )
}
