'use client'
import {Suspense, useEffect, useState} from "react";
import FilterList, {FiltersSkeleton} from "@/app/components/auctor/filter/FilterList";
import ResultList, {ResultsSkeleton} from "@/app/components/auctor/result/ResultList";
import {applyDynamicValues, applyFiltersOnResults, initAppliedFilters, sortBetweenFeaturedAndNormalResults} from "@/app/utils/filters-utils";
import {getComparatorResultsData} from "@/app/endpoints/auctorEndpoints";
import {IAppliedFilter, IFiltersConfigPayload} from "@/app/models/auctor";
import AppIcon from "@/app/components/atomic/AppIcon";
import {evaluateExpressionWithFilters} from "@/app/utils/expression-utils";

const LIST_LOADING_DURATION = 100;
interface ComparatorProps {
    comparatorId: number;
    templateConfig: any;
    initialResults: any;
    filtersConfig: IFiltersConfigPayload;
    pageConfig?: any;
    isPreview?: boolean;
    isMobileDevice?: boolean;
    showFiltersAbove?: boolean;
    filterListClasses?: string;
    gapBetweenFilters?: string;
    maxFiltersMobile?: number;
}

export default function Comparator({
    comparatorId,
    templateConfig,
    initialResults,
    filtersConfig,
    pageConfig,
    isPreview,
    isMobileDevice,
    showFiltersAbove = false,
    filterListClasses = 'bg-white border border-blue-300 p-4 rounded-lg lg:sticky grid items-center content-center h-fit w-full',
    gapBetweenFilters = 'gap-4',
    maxFiltersMobile = 2,
}: ComparatorProps) {

    const initialAppliedFilters = initAppliedFilters(filtersConfig.filters, pageConfig);

    const [appliedFilters, setAppliedFilters] = useState<IAppliedFilter[]>(initialAppliedFilters);
    const [filteredResults, setFilteredResults] = useState<any[]>(initialResults ?? []);
    const [featuredResults, setFeaturedResults] = useState<any[]>([]);
    const [fullResultsPayload, setFullResultsPayload] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [isFilterListExpandedOnMobile, setIsFilterListExpandedOnMobile] = useState<boolean>(false);
    const [maxFilterToDisplay, setMaxFilterToDisplay] = useState<number>(isMobileDevice ? maxFiltersMobile : filtersConfig?.filters?.length);

    const updateAndApplyDynamicValues = (filterToUpdate: IAppliedFilter, newValue: any) => {
        setAppliedFilters(prevAppliedFilters => {
            // First, update the applied filter value
            const updatedFilters = prevAppliedFilters.map(aFilter => {
                if (aFilter.filterId === filterToUpdate.filterId) {
                    return { ...aFilter, value: newValue };
                }
                return aFilter;
            });

            // Apply dynamic values to the updated filters
            return applyDynamicValues(updatedFilters);
        });

        setIsLoading(true);
        setTimeout(() => setIsLoading(false), LIST_LOADING_DURATION);
    };

    const filterResults = (results: any[], appliedFilters: IAppliedFilter[]) => {
        const fResults = applyFiltersOnResults(results, filtersConfig.filters, appliedFilters, filtersConfig.expression_filter)

        const sortedResults = sortBetweenFeaturedAndNormalResults(
            fResults,
            appliedFilters,
            filtersConfig.featured_offer_configs
        )

        setFilteredResults(sortedResults.results)
        setFeaturedResults(sortedResults.featuredResults)
    }

    const hasFilters = filtersConfig?.filters?.length > 0

    useEffect(() => {
        if (fullResultsPayload.length === 0) {
            return
        }
        filterResults(fullResultsPayload, appliedFilters)
    }, [appliedFilters, fullResultsPayload])

    useEffect(() => {
        const isSizeMDOrLower = window.matchMedia('(max-width: 768px)').matches

        if (isSizeMDOrLower) {
            setMaxFilterToDisplay(maxFiltersMobile)
        }

        setIsLoading(false);
        getComparatorResultsData(comparatorId).then((res) => {
            setFullResultsPayload(res);

            if (isPreview) {
                filterResults(res, appliedFilters)
            }
        });
    }, [])

    return (
        <div className='w-full bg-blue-100 text-blue-900 py-5 px-2.5 transition-all duration-300'>
            <div
                className={`flex justify-center relative flex-col ${showFiltersAbove ? 'lg:flex-col gap-rm-50 ' : 'lg:flex-row gap-5 '} container place-self-center mx-auto`}
            >
                {hasFilters && (
                    <div
                        className={`${filterListClasses}
                        ${showFiltersAbove ? 'lg:w-full' : 'lg:w-1/4'}
                        ${isPreview ? '' : 'lg:top-[90px]'}`}
                    >
                        <Suspense key="filters-suspense" fallback={<FiltersSkeleton countDesktop={filtersConfig?.filters?.length} />}>
                            <FilterList
                                className={`flex w-full ${showFiltersAbove ? 'flex-col md:flex-row' : 'flex-col'} ${gapBetweenFilters}`}
                                filtersConfig={filtersConfig?.filters}
                                appliedFilters={appliedFilters}
                                updateAppliedFilter={updateAndApplyDynamicValues}
                                displayNumberOnMobile={maxFilterToDisplay}
                                isFilterListExpandedOnMobile={isFilterListExpandedOnMobile}
                            />

                            {filtersConfig?.filters?.length > maxFilterToDisplay &&
                                <div
                                    className="flex bg-blue-600 text-white p-2 rounded-b-md cursor-pointer justify-center items-center"
                                    onClick={() => setIsFilterListExpandedOnMobile(!isFilterListExpandedOnMobile)}
                                >
                                    <AppIcon
                                        type="regular"
                                        iconClassName={isFilterListExpandedOnMobile ? 'fa-circle-minus' : 'fa-circle-plus'}
                                        className="mr-2"
                                    />
                                    Afficher {isFilterListExpandedOnMobile ? 'moins' : 'plus'} de critères
                                </div>
                            }
                        </Suspense>
                    </div>
                )}

                <div
                    className={`flex flex-col h-full w-full ${showFiltersAbove ? 'lg:w-full' : 'lg:w-3/4'}`}
                >
                    {isLoading && (
                        ResultsSkeleton({count: filteredResults.length})
                    )}

                    {!isLoading && filteredResults.length === 0 && (
                        <div
                            className={"flex flex-col items-center my-auto gap-4"}
                        >
                            <h2
                                className={"text-2xl text-center"}
                            >
                                Oups !
                            </h2>
                            <p
                                className={"text-center"}
                            >
                                Nous n&apos;avons pas d&apos;offre à vous proposer pour ce profil.
                            </p>
                        </div>
                    )}

                    <Suspense fallback={<ResultsSkeleton count={filteredResults.length} />}>
                        <ResultList
                            isPreview={isPreview}
                            isHidden={isLoading || filteredResults.length === 0}
                            templateConfig={templateConfig}
                            appliedFilters={appliedFilters}
                            featuredResults={featuredResults}
                            results={filteredResults}
                        />
                    </Suspense>
                </div>

            </div>
        </div>
    )
}

export function ComparatorSkeleton() {
    return (
        <>
            <div
                className={"flex gap-5 relative flex-col lg:flex-row container p-2 place-self-center"}
            >
                <div
                    className="grid items-center content-center h-full w-full border border-blue-300 bg-white rounded-lg lg:w-1/4 md:sticky md:top-[15px]"
                >
                    <div className="p-4 gap-4 grid">
                        <FiltersSkeleton countDesktop={3} />
                    </div>
                    <div
                        className="flex bg-blue-600 text-white p-2 rounded-b-md cursor-pointer justify-center items-center md:hidden"
                    >
                        <AppIcon
                            type="regular" iconClassName={'fa-circle-plus'} className="mr-2"
                        />
                        Afficher plus de critères
                    </div>
                </div>
                <div
                    className={"flex flex-col h-full w-full lg:w-3/4"}
                >
                    <ResultsSkeleton count={10} />
                </div>
            </div>
        </>
    )
}

