import React from "react";

interface PropsInterface {
    children: React.ReactNode
    className?: string
}

export default function AppForm({ children, className }: PropsInterface) {
    return <form className={className}>
        {/* Prevent iOS safari autofocus on load */}
        <input type="tel" size={5} tabIndex={-1} readOnly={true} className="fixed bg-none pointer-events-none cursor-default bg-transparent" />
        {children}
    </form>
}
